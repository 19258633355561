@tailwind base;
@tailwind components;
@tailwind utilities;
@import "styles/menu";
@import "styles/data";
@import "styles/generation";

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --clr-border: #333;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

* {
  font-family: var(--font-manrope) !important;
  &::-webkit-scrollbar {
    height: 8px;
    width: 5px;
    background-color: #f9fafb;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background-color: #d0d5dd;

    &:hover {
      background-color: #98a2b3;
    }
  }
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-rate .ant-rate-star:not(:last-child) {
  margin-inline-end: 4px !important;
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-1drr2mu).ant-table-wrapper .ant-table-thead > tr > td {
  background-color: white !important;
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-1drr2mu).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-1drr2mu).ant-table-wrapper .ant-table-thead > tr > td {
  color: #a0aec0 !important;
  font-size: 12px;
  font-weight: 600;
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-picker .ant-picker-input > input {
  font-size: 16px !important;
}

.split-chart {
  height: calc(50% - 8px);
  @media (max-width: 768px) {
    height: auto;
  }
}

.dash-custom {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23A0AEC0FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='24' stroke-linecap='round'/%3e%3c/svg%3e");
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-checkbox-disabled .ant-checkbox-inner {
  background: rgba(223, 234, 242, 1) !important;
  border-color: rgba(223, 234, 242, 1) !important;
}

:where(.css-dev-only-do-not-override-1drr2mu).ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: white !important;
}

.ant-layout-header {
  line-height: normal !important;
}
