.config-slider-generation {
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-handle::after {
    width: 16px;
    height: 16px;
    box-shadow: none;
    inset-inline-start: -1px;
    inset-block-start: -3px;
    background-color: #16dbcc;
  }
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-track {
    background-color: #4393ff;
  }
  &.ant-slider .ant-slider-handle:hover::before {
    display: none !important;
  }
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-handle:hover::after,
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-handle:active::after,
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-handle:focus::after {
    box-shadow: none;
    width: 16px;
    height: 16px;
    inset-inline-start: -1px;
    inset-block-start: -3px;
  }
}
