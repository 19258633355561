.navbar {
  box-shadow: none;
  width: 100%;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  .navbar-item {
    height: 58px !important;
    display: flex;
    align-items: center;
    transition: border-color 0.3s, background 0.3s, padding 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: relative;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
    padding-inline: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline: 4px;
    margin-block: 4px;
    width: calc(100% - 8px);
    white-space: nowrap;
    cursor: pointer;
    border-radius: 8px;
    padding-left: 0;
    svg {
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
    }
    span {
      flex: auto;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-inline-start: 10px;
      opacity: 1;
      transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s;
    }
    .hide-text {
      display: inline-block;
      opacity: 0;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
}
