.ant-tabs-tab {
  padding: 12px 27px !important;
  &.ant-tabs-tab-active {
    .ant-typography {
      color: #2d60ff !important;
    }
  }
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  height: 3px !important;
  border-radius: 10px 10px 0 0;
}

.ant-tag-close-icon {
  font-size: unset !important;
}

.config-slider-handler {
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-handle::after {
    border-radius: 4px !important;
    width: 16px;
    height: 16px;
    box-shadow: none;
  }
  &.ant-slider .ant-slider-handle:hover::before {
    display: none !important;
  }
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider-horizontal .ant-slider-track,
  :where(.css-dev-only-do-not-override-1drr2mu).ant-slider-horizontal .ant-slider-tracks {
    height: 8px;
  }
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider-horizontal .ant-slider-step {
    height: 8px;
  }
  &.config-slider-handler:where(.css-dev-only-do-not-override-1drr2mu).ant-slider
    > .ant-slider-handle-1.ant-slider-handle::after {
    background-color: #16dbcc;
  }
  &.config-slider-handler:where(.css-dev-only-do-not-override-1drr2mu).ant-slider
    > .ant-slider-handle-2.ant-slider-handle::after {
    background-color: #937af7;
  }
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider-horizontal .ant-slider-dot {
    display: none;
  }
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-handle:hover::after,
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-handle:active::after,
  &:where(.css-dev-only-do-not-override-1drr2mu).ant-slider .ant-slider-handle:focus::after {
    box-shadow: none;
    width: 16px;
    height: 16px;
    inset-inline-start: 0;
    inset-block-start: 0;
  }
}
